import './index.less';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Modal, message } from 'antd';
import store from 'store';
import { modifyPwd } from '@admin/api/Auth';
import { useTranslation } from 'react-i18next';
import { cryptoStr } from '@admin/utils/tools/base';

interface Iprops {
    onClose: () => {};
}

const ChangePassword: React.FC = (props: Iprops) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [form] = Form.useForm();

    useEffect(() => {
        const account = store.get('account');

        form.setFieldValue('username', account);
    }, []);

    const onConfirmChange = async () => {
        const { pwdOld, pwdNew } = form.getFieldsValue();
        const { errorCode } = await modifyPwd({
            pwdNew: cryptoStr(pwdNew),
            pwdOld: cryptoStr(pwdOld)
        });

        if (errorCode === 0) {
            message.success(t('message.pwdModifySuccess'), 1.5, () => {
                navigate('/login');
            });
        }
    };

    return (
        <Modal
            title={t('btn.modify pdw')}
            open={true}
            centered
            width="45.6rem"
            onOk={onConfirmChange}
            className="changePassword"
            onCancel={() => {
                props.onClose();
            }}
            okText={t('btn.save')}>
            <div className="ModalContent">
                <Form form={form} name="addTemplate" colon={false} layout="horizontal" labelCol={{ span: 9 }} wrapperCol={{ span: 10 }}>
                    <Form.Item name="username" label={t('placeholder.account')}>
                        <Input placeholder={t('placeholder.pleaseEnter', { name: '' }) as string} disabled />
                    </Form.Item>
                    <Form.Item name="pwdOld" label={t('word.pwdOld')}>
                        <Input.Password placeholder={t('placeholder.pleaseEnter', { name: '' }) as string} />
                    </Form.Item>
                    <Form.Item name="pwdNew" label={t('word.pwdNew')} style={{ marginBottom: '40px' }}>
                        <Input.Password placeholder={t('placeholder.pleaseEnter', { name: '' }) as string} />
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    );
};

export default ChangePassword;
