import { TQ_Post, TQ_Get } from '@admin/utils/tools/axios';

export const validateToken = (param = {}) => TQ_Get('/iot/usermanager/user/permissions', param, null, { isShowError: false });

// 登录
export const login = (param = {}) => TQ_Post('/iot/usermanager/user/login', param);

// 查询
export const query = (param = {}) => TQ_Post('/iot/usermanager/role/query', param);

// 删除
export const deleteRole = (param = {}) => TQ_Get('/iot/usermanager/role/delete', param);

// 权限树
export const getTreeData = (param = {}) => TQ_Get('/iot/usermanager/role/tree', param);

// 角色详细信息
export const getDetails = (param = {}) => TQ_Get('/iot/usermanager/role/details', param);

// 修改角色
export const editRole = (param = {}) => {
    return TQ_Post(`/iot/usermanager/role/update?roleId=${param.query}`, param.body);
};

// 新建角色
export const addRole = (param = {}) => TQ_Post('/iot/usermanager/role/add', param);

// 用户添加
export const addUser = (param = {}) => TQ_Post('/iot/usermanager/user/add', param);

// 用户修改
export const editUser = (param = {}) => {
    return TQ_Post(`/iot/usermanager/user/update?useId=${param.query}`, param.body);
};

// 用户查询
export const queryUser = (param = {}) => TQ_Post('/iot/usermanager/user/query', param);

// 重置
export const reset = (param = {}) => TQ_Get('/iot/usermanager/user/login/reset', param);

// 删除
export const deleteUser = (param = {}) => TQ_Get('/iot/usermanager/user/delete', param);

export const userDetail = (param = {}) => TQ_Get('/iot/usermanager/user/details', param);

export const modifyPwd = (param = {}) => TQ_Post('/iot/usermanager/user/login/modify', param);
