import { Layout, Breadcrumb, Dropdown, Space } from 'antd';
import React, { useState } from 'react';
import { useMatches, useNavigate } from 'react-router-dom';
import type { MenuProps } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import Setting from '@admin/assets/svg/setting.svg';
import { langs, changeLanguage } from '@admin/locales/i18n';
import './index.less';
import store from 'store';
import ChangePassword from './ChangePassword';
import { useTranslation } from 'react-i18next';
import { useAppStore } from '@admin/store/app';

const { Header } = Layout;

const LayoutHeader: React.FC = () => {
    const match = useMatches();
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { routes } = useAppStore();
    const [showChangePassword, setShowChangePassword] = useState(false);

    // const BreadcrumbItems = match.filter(({ data: { index } }) => !index).map(({ data: { title } }) => ({ title: t(`menu.${title}`) }));

    const BreadcrumbItems = match.reduce<{ title: string }[]>((prev, curr) => {
        const { pathname } = curr;
        const found = routes.find(({ path }) => path === pathname);

        if (found && found.title) {
            const key = `menu.${found.title}`;

            prev.push({ title: t(key) });
        }

        return prev;
    }, []);

    const onHeaderSetting = () => {};

    const onChangePassword = () => {
        setShowChangePassword(true);
    };

    const onExit = () => {
        store.set('token');
        navigate('/login');
    };

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <div className="toolItem" onClick={onChangePassword}>
                    {t('btn.modify pdw')}
                </div>
            )
        },
        {
            key: '2',
            label: (
                <div className="toolItem" onClick={onExit}>
                    {t('btn.exit')}
                </div>
            )
        }
    ];

    return (
        <Header className="!bg-transparent ">
            <Breadcrumb items={BreadcrumbItems} separator=">" />

            <div className="flex items-center">
                <Dropdown
                    menu={{
                        items: langs,
                        onClick: e => {
                            changeLanguage(e.key);
                        }
                    }}>
                    <Space className="mr-5">
                        {langs.find(({ label, key }) => key === i18n.language)?.label ?? ''}
                        <DownOutlined />
                    </Space>
                </Dropdown>

                <Dropdown menu={{ items }} placement="bottomRight" arrow>
                    <img src={Setting} alt="" className="header-set" onClick={onHeaderSetting} />
                </Dropdown>
            </div>

            {showChangePassword ? (
                <ChangePassword
                    onClose={() => {
                        setShowChangePassword(false);
                    }}
                />
            ) : null}
        </Header>
    );
};

export default LayoutHeader;
