import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleProvider } from '@ant-design/cssinjs';
import { ConfigProvider } from 'antd';
import Loading from './components/common/Loading';
import { useAppStore } from './store/app';
import { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { validateToken } from '@admin/api/Auth';
import { SeedToken, components } from './theme';
import { hashRoutes, mainRoutes } from './router';
import zhCN from 'antd/locale/zh_CN';
import enUs from 'antd/locale/en_US';
import 'dayjs/locale/zh-cn';

const langs = {
    zh: zhCN,
    en: enUs
};

const flatRoutes = (tempRoutes, prefix = '', permissionParents = []) => {
    const currRoutes = tempRoutes.reduce((prev, { index, path = '', title, auth, icon, permission, redirect, children, hide }) => {
        const route = { path: `${prefix}/${path.replace('/', '')}`, title, auth, icon, permission, permissionParents, hide };
        let childrenRoutes = [];

        if (children) {
            const rootPath = `${prefix}/${path.replace('/', '')}`;

            childrenRoutes = flatRoutes(children, rootPath, [...permissionParents, permission]);
        }

        if (index || redirect) {
            return [...prev, ...childrenRoutes];
        }

        return [...prev, route, ...childrenRoutes];
    }, []);

    return currRoutes;
};

const resizeListener = () => {
    const designSize = 1280;
    const html = document.documentElement;
    const clientW = html.clientWidth;
    const htmlRem = (clientW * 10) / designSize;

    html.style.fontSize = htmlRem + 'px';
};

function App() {
    const { themeParams, setUserInfo, setRoutes } = useAppStore();
    const { i18n } = useTranslation();

    // const navigate = useNavigate();

    // const router = createHashRouter(routes);

    // const location = useLocation();
    // const { pathname } = location;
    // const navigate = useNavigate();
    // const token = store.get('token');

    useEffect(() => {
        resizeListener();
        window.addEventListener('resize', resizeListener);

        setRoutes(flatRoutes(mainRoutes));

        validateToken().then(res => {
            console.log(res);
            const { body, errorCode } = res;

            if (errorCode === 0) {
                setUserInfo(body);
            }

            // setIsAuthenticated(true);
            // window.location = '/login'; // 如果用
        });

        return () => {
            window.removeEventListener('resize', resizeListener);
        };
    }, []);

    // useEffect(() => {
    //   if (pathname === '/') {
    //     navigate('/login');
    //   }

    //   if (!noLogin.includes(pathname) && !token) {
    //     navigate('/login');
    //   }
    // }, [pathname]);

    return (
        <Suspense fallback={<Loading />}>
            <ConfigProvider
                locale={langs[i18n.language]}
                theme={{
                    token: {
                        ...themeParams,
                        ...SeedToken
                    },
                    components
                }}>
                <StyleProvider hashPriority="high">
                    <RouterProvider router={hashRoutes} />
                </StyleProvider>
            </ConfigProvider>
        </Suspense>
    );
}

export default App;
