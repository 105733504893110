import { ReactComponent as HomeIcon } from '@admin/assets/svg/home.svg';
import { ReactComponent as ControlIcon } from '@admin/assets/svg/control.svg';
import { ReactComponent as BurningIcon } from '@admin/assets/svg/burning.svg';
import { ReactComponent as DataIcon } from '@admin/assets/svg/data.svg';
import { ReactComponent as PermissionIcon } from '@admin/assets/svg/permission.svg';
import { lazy, ReactNode } from 'react';
import PermissionRoute from '@admin/components/PermissionRoute';
import Login from '@admin/views/Login';
import Layout from '@admin/components/Layout';
import { createHashRouter, Outlet } from 'react-router-dom';

export const mainRoutes = [
    {
        path: '/home',
        title: 'home',
        element: <Outlet />,
        icon: HomeIcon,
        children: [
            {
                index: true,
                element: PermissionRoute(lazy(() => import('@admin/views/HomeIndex')))
            }
        ]
    },
    // 远程控制
    {
        path: '/remote',
        title: 'remoteControl',
        element: <Outlet />,
        icon: ControlIcon,
        permission: '100000',
        children: [
            {
                path: 'transOrder',
                title: 'transparentCommand',
                element: PermissionRoute(
                    lazy(() => import('@admin/views/TransOrder')),
                    '100010'
                ),
                permission: '100010'
            },
            {
                path: 'gridParams/:id',
                title: 'gridConnectionParameters',
                element: PermissionRoute(
                    lazy(() => import('@admin/views/GridParams')),
                    '100020'
                ),
                params: {
                    id: 'gridParams'
                },
                permission: '100020'
            },
            {
                path: 'savingParams/:id',
                title: 'energySavingParameters',
                element: PermissionRoute(
                    lazy(() => import('@admin/views/GridParams')),
                    '100030'
                ),
                params: {
                    id: 'savingParams'
                },
                permission: '100030'
            },
            {
                path: 'safety/:id',
                title: 'safetyRegulationParameters',
                element: PermissionRoute(
                    lazy(() => import('@admin/views/GridParams')),
                    '100040'
                ),
                params: {
                    id: 'safety'
                },
                permission: '100040'
            },
            {
                path: 'device/:id',
                title: 'intelligentDeviceParameters',
                element: PermissionRoute(
                    lazy(() => import('@admin/views/GridParams')),
                    '100050'
                ),
                params: {
                    id: 'device'
                },
                permission: '100050'
            }
        ]
    },
    // 烧录管理
    {
        path: '/burning',
        title: 'burnerManagement',
        icon: BurningIcon,
        element: <Outlet />,
        permission: '200000',
        children: [
            {
                path: 'firmware',
                title: 'firmwareManagement',
                element: PermissionRoute(
                    lazy(() => import('@admin/views/FirmwareManage')),
                    '200010'
                ),
                permission: '200010'
            },
            {
                path: 'device',
                title: 'deviceBurning',
                element: PermissionRoute(
                    lazy(() => import('@admin/views/BurnManage')),
                    '200020'
                ),
                permission: '200020'
            }
        ]
    },
    // 数据查询
    {
        path: '/data',
        title: 'dataInquiry',
        icon: DataIcon,
        element: <Outlet />,
        permission: '300000',
        children: [
            {
                path: 'deviceInfo',
                title: 'deviceInfo',
                element: PermissionRoute(lazy(() => import('@admin/views/DeviceInfo')))
            },
            {
                path: 'template',
                title: 'queryTemplate',
                element: PermissionRoute(
                    lazy(() => import('@admin/views/QueryTemplate')),
                    '300010'
                ),
                permission: '300010'
            },
            {
                path: 'exportRecord',
                title: 'exportRecord',
                element: PermissionRoute(
                    lazy(() => import('@admin/views/ExportRecord')),
                    '300015'
                ),
                permission: '300015'
            },
            {
                path: 'inverterDataAnalysis',
                title: 'inverterDataAnalysis',
                element: PermissionRoute(
                    lazy(() => import('@admin/views/InverterDataAnalysis')),
                    '300022'
                ),
                permission: '300022'
            },
            {
                path: 'inverterInstant/:id',
                title: 'inverterConfigurationData',
                element: PermissionRoute(
                    lazy(() => import('@admin/views/InverterData')),
                    '300030'
                ),
                params: {
                    id: 'INVERTER_BASIC'
                },
                permission: '300030'
            },
            {
                path: 'inverterBasic/:id',
                title: 'inverterData',
                element: PermissionRoute(
                    lazy(() => import('@admin/views/InverterData')),
                    '300020'
                ),
                params: {
                    id: 'INVERTER_INSTANT'
                },
                permission: '300020'
            },
            {
                path: 'smartInstant/:id',
                title: 'smartDeviceConfigurationData',
                element: PermissionRoute(
                    lazy(() => import('@admin/views/InverterData')),
                    '300050'
                ),
                params: {
                    id: 'SMART_DEVICE_BASIC'
                },
                permission: '300050'
            },
            {
                path: 'smartBasic/:id',
                title: 'intelligentDeviceData',

                element: PermissionRoute(
                    lazy(() => import('@admin/views/InverterData')),
                    '300040'
                ),
                params: {
                    id: 'SMART_DEVICE_INSTANT'
                },
                permission: '300040'
            }
        ]
    },
    // 权限管理
    {
        path: '/permission',
        title: 'permissionManagement',
        element: <Outlet />,
        icon: PermissionIcon,
        permission: '400000',
        children: [
            {
                path: 'role',
                title: 'roleManagement',
                element: PermissionRoute(
                    lazy(() => import('@admin/views/RoleManagement')),
                    '400010'
                ),
                permission: '400010'
            },
            {
                path: 'user',
                title: 'userManagement',
                element: PermissionRoute(
                    lazy(() => import('@admin/views/Usermanagement')),
                    '400020'
                ),
                permission: '400020'
            }
        ]
    },
    // 天青词库
    {
        path: '/manage',
        title: 'manage',
        hide: true,
        element: <Outlet />,
        icon: PermissionIcon,
        permission: '500000',
        children: [
            {
                path: 'wordDatabase',
                title: 'solWordDatabase',
                permission: '500100'
            }
        ]
    }
];

export const routes = [
    {
        path: '/login',
        element: <Login />
    },
    {
        path: '/',
        element: <Layout />,
        children: mainRoutes
    }
];

// export const routes = [...BASE_ROUTER, ...WHITE_ROUTER];

export const hashRoutes = createHashRouter(routes);

export interface Route {
    path: string;
    auth?: boolean;
    element?: ReactNode;
    redirect?: string;
    title?: string;
    icon?: undefined;
    children?: undefined;
    permission?: undefined;
}
